import Search from "./components/Search";
import Search2 from "./components/Search2";
import Search3 from "./components/Search3";
import NavigationBar from "./components/NavigationBar";
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import HomePage from "./pages/Homepage";
import WorkShop from "./pages/WorkShop";
import Landing from "./pages/Landing";
import { useMediaQuery } from "react-responsive";
import { SwitchToDesktop } from "./components/SwitchToDesktop";
import Shop from "./pages/Shop";

function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 900px)` });
  return (
    <>
      {/*<Search/>*/}
      {/*<Search2/>*/}

      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Landing isMobileView={isMobile} />} />
          <Route
            exact
            path="/workshop"
            element={<WorkShop isMobileView={isMobile} />}
          />
          <Route
            exact
            path="/shop"
            element={<Shop isMobileView={isMobile} />}
          />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Search3 from "../components/Search3";

import { localData } from "../data/localDataUtils";
import FunctionalTabs from "../components/FunctionalTabs";
import Footer3 from "../components/Footer3";
const WorkShop = ({ isMobileView }) => {
  const [collection, setCollection] = useState("azuki");
  const [activeStickers, setActiveStickers] = useState([]);
  const [isLoadingMainImage, setIsLoadingMainImage] = useState(false);
  const [isLoadingCompanionImage, setIsLoadingCompanionImage] = useState(false);

  // It will be removed from here in future
  const [idTarget, setIdTarget] = useState("");
  const [companion, setCompanion] = useState(false);
  const [selfie, setSelfie] = useState(false);
  const [beanzId, setBeanzId] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [companionImageUrl, setCompanionImageUrl] = useState("");
  const [orderOpen, setOrderOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);

  function handleStickerClick(stickerSrc) {
    setActiveStickers((prevStickers) => {
      const stickerIndex = prevStickers.findIndex(
        (sticker) => sticker.src === stickerSrc
      );
      if (stickerIndex > -1) {
        // Se lo sticker è già presente, rimuovilo

        localData.removeMedia(collection, stickerSrc);
        return [
          ...prevStickers.slice(0, stickerIndex),
          ...prevStickers.slice(stickerIndex + 1),
        ];
      } else {
        // Altrimenti, aggiungi lo sticker all'array
        const prevList = prevStickers.map((sticker) => {
          return {
            ...sticker,
            controlsActive: false,
          };
        });
        return [
          ...prevList,
          { src: stickerSrc, x: 0, y: 0, controlsActive: true },
        ];
      }
    });
  }

  const toggleFooter = () => {
    setFooterOpen(!footerOpen);
  };

  useEffect(() => {
    setFooterOpen(false);
  }, []);

  return (
    <section className="workshop flex flex-col h-full bg-zinc-100">
      <Search3
        collection={collection}
        setCollection={setCollection}
        orderOpen={orderOpen}
        setOrderOpen={setOrderOpen}
        idTarget={idTarget}
        companion={companion}
        selfie={selfie}
        beanzId={beanzId}
        imageUrl={imageUrl}
        companionImageUrl={companionImageUrl}
        isLoadingMainImage={isLoadingMainImage}
        setIsLoadingMainImage={setIsLoadingMainImage}
        isLoadingCompanionImage={isLoadingCompanionImage}
        setIsLoadingCompanionImage={setIsLoadingCompanionImage}
        activeStickers={activeStickers}
        setActiveStickers={setActiveStickers}
        handleStickerClick={handleStickerClick}
        toggleFooter={toggleFooter}
        isMobileView={isMobileView}
      />
      <FunctionalTabs
        activeStickers={activeStickers}
        setActiveStickers={setActiveStickers}
        orderOpen={orderOpen}
        collection={collection}
        setCollection={setCollection}
        idTarget={idTarget}
        setIdTarget={setIdTarget}
        companion={companion}
        setCompanion={setCompanion}
        selfie={selfie}
        setSelfie={setSelfie}
        beanzId={beanzId}
        setBeanzId={setBeanzId}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        companionImageUrl={companionImageUrl}
        setCompanionImageUrl={setCompanionImageUrl}
        isLoadingMainImage={isLoadingMainImage}
        setIsLoadingMainImage={setIsLoadingMainImage}
        isLoadingCompanionImage={isLoadingCompanionImage}
        setIsLoadingCompanionImage={setIsLoadingCompanionImage}
        handleStickerClick={handleStickerClick}
        isMobileView={isMobileView}
      ></FunctionalTabs>
      {!isMobileView && (
        <Footer3 isOpen={footerOpen} toggleFooter={toggleFooter} />
      )}
      {/*<Search4/>*/}
    </section>
  );
};

export default WorkShop;

import React, { useEffect, useState } from "react";

import shopStyle from "../styles/pages/Shop.module.css";
import detailStyle from "../styles/pages/ProductDetail.module.css";

import Topbar from "../components/Topbar";
import Footer3 from "../components/Footer3";

import { products } from "../data/products";
import { Link } from "react-router-dom";
import Cart from "../components/Cart";

import closeIcon from "../asset/close_X.svg";
import MobileMenu from "../components/MobileMenu";

const servercall = process.env.REACT_APP_SERVER_BASE_URL;

const Shop = ({ isMobileView }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductKey, setSelectedProductKey] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [productKeys, setProductKeys] = useState([]);

  // As the project grows, we can use a context to manage the cart state
  // at this moment, there is no redux
  const [cart, setCart] = useState([]); // {product, size, quantity}
  const [cartQuantity, setCartQuantity] = useState([]);

  useEffect(() => {
    // Fetch the products from the server
    const prodRequest = async () => {
      const prod = await fetch(`${servercall}products`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const prodData = await prod.json();

      // Get the keys of the products that are available in the client
      let keys = [];
      keys = prodData.products.map((item) => item.id);
      keys = keys.filter((key) => products[key] !== undefined);

      // Get the sizes of the products
      let sizes = {};
      prodData.products.forEach((item) => {
        if (item.metadata.sizes === undefined || keys.indexOf(item.id) === -1)
          return;

        sizes[item.id] = JSON.parse(item.metadata.sizes);
      });

      setSizes(sizes);
      setProductKeys(keys);
    };

    prodRequest();
  }, []);

  useEffect(() => {
    const quantity = cart.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);

    setCartQuantity(quantity);
  }, [cart]);

  const toggleCart = () => {
    setCartOpen(!cartOpen);
  };

  const toggleFooter = () => {
    setFooterOpen(!footerOpen);
  };

  const handleDecrease = () => {
    if (selectedQuantity > 1) {
      setSelectedQuantity(selectedQuantity - 1);
    }
  };

  const handleIncrease = () => {
    setSelectedQuantity(selectedQuantity + 1);
  };

  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 1) {
      setSelectedQuantity(value);
    } else if (value < 1) {
      setSelectedQuantity(1);
    }
  };

  const resetProduct = () => {
    setSelectedProduct(null);
    setSelectedImage(0);
    setSelectedSize(0);
    setSelectedQuantity(0);
    setHoveredIndex(-1);
  };

  const TopbarChildren = (
    <>
      {!isMobileView && (
        <button
          className="rounded-3xl w-x10 h-34 bg-white border border-gray-200 font-semibold text-kobo-16"
          onClick={toggleFooter}
        >
          Terms of Service
        </button>
      )}
      <button
        className={`rounded-3xl w-34 h-34 border text-white z-[99] bg-kobo-o1 border-kobo-o2 font-semibold text-kobo-16`}
        onClick={toggleCart}
      >
        {cartOpen ? (
          <img
            src={closeIcon}
            alt="close icon"
            className="w-6 h-6 cursor-pointer m-auto"
          />
        ) : (
          cartQuantity
        )}
      </button>
    </>
  );

  const handleAddToCart = (productKey) => {
    if (productKey) {
      const existingProduct = cart.find(
        (item) => item.product.name === products[productKey].name
      );
      if (existingProduct) {
        existingProduct.quantity += 1;
        setCart([...cart]);
      } else {
        const newItem = {
          image: products[productKey].images[0],
          product: products[productKey],
          size: sizes[productKey] ? sizes[productKey][0] : null,
          quantity: 1,
          prod_key: productKey,
        };
        setCart([...cart, newItem]);
      }
    } else {
      const existingProduct = cart.find(
        (item) =>
          item.product.name === selectedProduct.name &&
          item.size === selectedSize
      );
      if (existingProduct) {
        existingProduct.quantity += selectedQuantity;
        setCart([...cart]);
      } else {
        const newItem = {
          image: selectedProduct.images[0],
          product: selectedProduct,
          size: selectedSize,
          quantity: selectedQuantity,
          prod_key: selectedProductKey,
        };
        setCart([...cart, newItem]);
      }
    }

    resetProduct();
  };

  return (
    <section className={shopStyle.shop}>
      <Topbar
        showHamburger={isMobileView}
        hamburgerClicked={() => setMenuOpen(!menuOpen)}
        showNavigationLinks={!isMobileView}
        childrenEnd={TopbarChildren}
        customCallback={() => resetProduct()}
      />

      {isMobileView && (
        <MobileMenu
          open={menuOpen}
          closeMobileMenu={() => setMenuOpen(!menuOpen)}
        />
      )}

      {!selectedProduct && (
        <div className={shopStyle.shop__products}>
          {productKeys.map((productKey, index) => (
            <div
              key={index}
              className={shopStyle.shop__product}
              onMouseOver={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
              onClick={() => {
                setSelectedProduct(products[productKey]);
                setSelectedProductKey(productKey);
              }}
            >
              <img
                src={products[productKey].images[0]}
                alt={products[productKey].name}
              />
              {hoveredIndex === index && (
                <div className={shopStyle.shop__productInfoContainer}>
                  <div className={shopStyle.shop__productInfo}>
                    <p className={shopStyle.shop__productName}>
                      {products[productKey].name}
                    </p>
                    <p className={shopStyle.shop__productPrice}>
                      ${products[productKey].price}
                    </p>
                  </div>
                  <div className={shopStyle.shop__productActions}>
                    <button
                      className={shopStyle.shop__quickBuyButton}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      Quick Buy
                    </button>
                    <button
                      className={shopStyle.shop__addToCartButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddToCart(productKey);
                      }}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
          {productKeys.map((productKey, index) => (
            <div
              key={index}
              className={shopStyle.shop__product}
              onMouseOver={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
              onClick={() => {
                setSelectedProduct(products[productKey]);
                setSelectedProductKey(productKey);
              }}
            >
              <img
                src={products[productKey].images[0]}
                alt={products[productKey].name}
              />
            </div>
          ))}
          {productKeys.map((productKey, index) => (
            <div
              key={index}
              className={shopStyle.shop__product}
              onMouseOver={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
              onClick={() => {
                setSelectedProduct(products[productKey]);
                setSelectedProductKey(productKey);
              }}
            >
              <img
                src={products[productKey].images[0]}
                alt={products[productKey].name}
              />
            </div>
          ))}
          {productKeys.map((productKey, index) => (
            <div
              key={index}
              className={shopStyle.shop__product}
              onMouseOver={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(-1)}
              onClick={() => {
                setSelectedProduct(products[productKey]);
                setSelectedProductKey(productKey);
              }}
            >
              <img
                src={products[productKey].images[0]}
                alt={products[productKey].name}
              />
            </div>
          ))}
        </div>
      )}
      {selectedProduct && (
        <div className={detailStyle.detail__productDetail}>
          <div className={detailStyle.detail__productDetailLeft}>
            <div className={detailStyle.detail__mainImageContainer}>
              <img
                src={selectedProduct.images[selectedImage]}
                alt={selectedProduct.name}
                className={detailStyle.detail__mainImage}
              />
            </div>
            <div className={detailStyle.detail__circleContainer}>
              {selectedProduct.images.map((img, index) => (
                <div
                  key={index}
                  className={`${detailStyle.detail__circle} ${
                    selectedImage === index
                      ? detailStyle.detail__circleActive
                      : ""
                  }`}
                  onClick={() => setSelectedImage(index)}
                ></div>
              ))}
            </div>
          </div>
          <div className={detailStyle.detail__productDetailRight}>
            <div className={detailStyle.detail__productDetailInfo}>
              <div className={detailStyle.detail__productDetailInfoWrapper}>
                <div className={detailStyle.detail__productDetailInfoHeader}>
                  <h1 className={detailStyle.detail__productTitle}>
                    {selectedProduct.name}
                  </h1>
                  <p className={detailStyle.detail__productPrice}>
                    ${selectedProduct.price}
                  </p>
                </div>
                <div className={detailStyle.detail__productDetailInfoActions}>
                  <div
                    className={detailStyle.detail__productDetailInfoActionsSize}
                  >
                    <label htmlFor="size">Size</label>
                    <select
                      className={detailStyle.detail__selection}
                      id="size"
                      value={selectedSize}
                      onChange={(e) => setSelectedSize(e.target.value)}
                    >
                      {sizes[selectedProductKey] &&
                        sizes[selectedProductKey].map((size, index) => (
                          <option key={index} value={size}>
                            {size}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div
                    className={
                      detailStyle.detail__productDetailInfoActionsQuantity
                    }
                  >
                    <label htmlFor="quantity">Quantity</label>
                    <div
                      className={
                        (detailStyle.detail__quantityInput,
                        detailStyle.detail__selection)
                      }
                    >
                      <button
                        className={detailStyle.detail__quantityButton}
                        onClick={handleDecrease}
                      >
                        -
                      </button>
                      <input
                        type="number"
                        id="quantity"
                        value={selectedQuantity}
                        onChange={handleChange}
                        min={1}
                      />
                      <button
                        className={detailStyle.detail__quantityButton}
                        onClick={handleIncrease}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <button
                    className={detailStyle.detail__productQuickBuyButton}
                  ></button>
                  <button
                    className={detailStyle.detail__productAddToCart}
                    onClick={() => handleAddToCart()}
                  ></button>
                </div>
                <pre className={detailStyle.detail__productDescription}>
                  {selectedProduct.description}
                </pre>
              </div>
            </div>
          </div>
        </div>
      )}
      {
        <div className={shopStyle.shop__cartPosition}>
          <Cart cartProducts={cart} visible={cartOpen}></Cart>
        </div>
      }
      {!isMobileView && (
        <Footer3 isOpen={footerOpen} toggleFooter={toggleFooter} />
      )}
    </section>
  );
};

export default Shop;

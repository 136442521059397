import React, { useEffect, useState, useRef, memo } from "react";
import { act } from "react-dom/test-utils";
import { Stage, Layer, Image as KonvaImage, Transformer } from "react-konva";
import useImage from "use-image";
import { localData } from "../data/localDataUtils";

/*const StickerImage = memo(({ url, scaledMask, isBeanzCollection }) => {
    const [sticker, stickerStatus] = useImage(url);
 const [scaledSticker, setScaledSticker] = useState(null);
 const [stickerPosition, setStickerPosition] = useState({ x: 0, y: 0 });
 const [isSelected, setIsSelected] = useState(false);
 const stickerRef = useRef(null);
 const trRef = useRef(null);

 const scaleSticker = (stickerImg, targetWidth, targetHeight) => {
    const stickerRatio = stickerImg.width / stickerImg.height;
    let scale = Math.min(targetWidth / stickerImg.width, targetHeight / stickerImg.height);
    return {
      width: (stickerImg.width * scale)*0.1,
      height: (stickerImg.height * scale)*0.1,
      scale: scale // Salva il fattore di scala per utilizzarlo durante il drag
    };
 };


 useEffect(() => {
    if (isSelected) {
      // Aggiungiamo il nodo dello sticker al transformer
      trRef.current.nodes([stickerRef.current]);
      trRef.current.getLayer().batchDraw();
    } else {
      // Se non è selezionato, rimuoviamo il transformer
      if (trRef.current) {
        trRef.current.nodes([]);
        trRef.current.getLayer().batchDraw();
      }
    }
  }, [isSelected, stickerStatus]);

  const handleStickerClick = () => {
    // Inverte lo stato di selezione
    setIsSelected(!isSelected);
  };



 useEffect(() => {
    if (stickerStatus === 'loaded' && sticker && scaledMask) {
      const scaledStickerSize = scaleSticker(sticker, scaledMask.width, scaledMask.height);
      setScaledSticker(scaledStickerSize);
      let stickerPosX = scaledMask.x + (scaledMask.width - scaledStickerSize.width) / 2;
      let stickerPosY = scaledMask.y + (scaledMask.height - scaledStickerSize.height) / 2;
      if (isBeanzCollection) {
        stickerPosX = 240;
        stickerPosY = 240;
      }
      setStickerPosition({
        x: stickerPosX,
        y: stickerPosY
      });
    }
    
 }, [sticker, stickerStatus, scaledMask]);


 console.log('sticker position!', stickerPosition.x, stickerPosition.y)
 console.log('STICKERRRRR', sticker)
  return (
    stickerStatus === 'loaded' && sticker &&  (
        <>
        <KonvaImage
          key={url}
          image={sticker}
          x={stickerPosition.x}
          y={stickerPosition.y}
          width={scaledSticker?.width}
          height={scaledSticker?.height}
          draggable
          onDragStart={(e) => {}}
          onDragEnd={(e) => {
            const newPos = {
                x: e.target.x(),
                y: e.target.y()
            };
            setStickerPosition(newPos);
            localStorage.setItem(url, JSON.stringify(newPos));
        }}
          globalCompositeOperation='source-atop'
          onClick={() => setIsSelected(true)}
            onTap={() => setIsSelected(true)}
            ref={stickerRef}
        />
        {isSelected && (
        <Transformer
            onClick={handleStickerClick}
            onTap={handleStickerClick}
          ref={trRef}
          rotateEnabled={true}
          enabledAnchors={[]}
          boundBoxFunc={(oldBox, newBox) => newBox} // Impedisce il ridimensionamento
        />
      )}
       </> 
        )
  )
})
export default StickerImage*/

const StickerImage = memo(
  ({
    url,
    collection,
    scaledMask,
    isBeanzCollection,
    activeStickers,
    controlsActive,
    deleteFired,
    deleteEvent,
    scaleRatio,
    setActiveStickers,
  }) => {
    const [sticker, stickerStatus] = useImage(url);
    const [stickerData, setStickerData] = useState({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      rotation: 0,
    });
    const stickerRef = useRef(null);
    const trRef = useRef(null);
    const lastActiveStickerRef = useRef(null);
    const [controls, setControls] = useState(controlsActive);

    const deltaError = 15; // Margine di errore dove uno sticker può essere posizionato

    const scaleSticker = (stickerImg, targetWidth, targetHeight) => {
      let scale = Math.min(
        targetWidth / stickerImg.width,
        targetHeight / stickerImg.height
      );
      return {
        width: stickerImg.width * scale * 0.09,
        height: stickerImg.height * scale * 0.09,
      };
    };

    useEffect(() => {
      if (deleteFired && deleteEvent && sticker && controls) {
        deleteEvent(sticker.getAttribute("src"));
      }
    }, [deleteFired]);

    useEffect(() => {
      if (stickerStatus === "loaded" && sticker && scaledMask) {
        let storedData = localData.getCollection(collection);

        if (storedData && storedData[url]) {
          setStickerData(storedData[url]);
        } else {
          const scaledStickerSize = scaleSticker(
            sticker,
            scaledMask.width,
            scaledMask.height
          );
          let posX =
            scaledMask.x + (scaledMask.width - scaledStickerSize.width) / 2;
          let posY =
            scaledMask.y + (scaledMask.height - scaledStickerSize.height) / 2;

          if (isBeanzCollection) {
            posX = 250;
            posY = 250;
          }

          const initialData = {
            x: posX,
            y: posY,
            width: scaledStickerSize.width,
            height: scaledStickerSize.height,
            rotation: 0,
          };

          setStickerData(initialData);
          localData.setMedia(collection, url, initialData);
          // const toStore = storedData || {};
          // toStore[url] = initialData;
          // localStorage.setItem(collection, JSON.stringify(toStore));
          trRef.current.nodes([stickerRef.current]);
        }
        //aggiunta per vedere subito trasformatore
        //trRef.current.nodes([stickerRef.current]);
      }
    }, [
      url,
      sticker,
      scaledMask,
      stickerStatus,
      isBeanzCollection,
      collection,
      activeStickers,
    ]);

    useEffect(() => {
      setControls(controlsActive);
      if (stickerStatus === "loaded" && sticker && scaledMask) {
        if (!controlsActive) {
          trRef.current.nodes([]);
        }
      }
    }, [controlsActive]);

    const handleTransformEnd = () => {
      if (!stickerRef.current) return;
      const node = stickerRef.current;

      /**
       * Controlla se lo sticker si trova fuori dai margini della tavola
       * Attualmente i valori sono hard coded ma si possono dinamicizzare
       */
      const maxHeight = scaleRatio * (isBeanzCollection ? 500 : 310);
      const maxWidth = scaleRatio * (isBeanzCollection ? 310 : 500);
      const minHeight = scaleRatio * (isBeanzCollection ? 0 : 200);
      const minWidth = scaleRatio * (isBeanzCollection ? 200 : 0);

      let newX = node.x();
      let newY = node.y();

      if (newX - maxWidth > deltaError || newX - minWidth < -deltaError) {
        newX = (maxWidth + minWidth) / 2 + Math.random();
      }

      if (newY - maxHeight > deltaError || newY - minHeight < -deltaError) {
        newY = (maxHeight + minHeight) / 2 + Math.random();
      }

      const updatedData = {
        x: newX,
        y: newY,
        width: node.width() * node.scaleX(),
        height: node.height() * node.scaleY(),
        rotation: node.rotation(),
      };

      setStickerData(updatedData);
      localData.setMedia(collection, url, updatedData);
      // let toStore = localStorage.getItem(collection);
      // toStore = JSON.parse(toStore);
      // console.log("UPDATE STORE", toStore);
      // toStore[url] = updatedData;
      // localStorage.setItem(collection, JSON.stringify(toStore));
    };

    const handleClick = () => {
      const isSelected = trRef.current?.nodes().length > 0;
      setControls(!isSelected);
      if (isSelected) {
        trRef.current.nodes([]);
      } else {
        trRef.current.nodes([stickerRef.current]);
        setActiveStickers((prevStickers) => {
          return prevStickers.map((sticker) => {
            if (sticker.src !== url) return sticker;

            return { src: url, x: 0, y: 0, controlsActive: true };
          });
        });
      }
      trRef.current.getLayer().batchDraw();
    };

    const konvaImage = (
      <KonvaImage
        key={url}
        image={sticker}
        {...stickerData}
        draggable
        onDragEnd={handleTransformEnd}
        onTransformEnd={handleTransformEnd}
        onClick={handleClick}
        onTap={handleClick}
        ref={stickerRef}
        globalCompositeOperation="source-atop"
      />
    );

    return (
      stickerStatus === "loaded" &&
      sticker && (
        <>
          {konvaImage}
          <Transformer
            ref={trRef}
            rotateEnabled={true}
            enabledAnchors={[]}
            boundBoxFunc={(oldBox, newBox) => newBox} // Impedisce il ridimensionamento
            attachedTo={stickerRef.current}
            rotateAnchorOffset={10}
          />
        </>
      )
    );
  }
);

export default StickerImage;

export const localData = {
  add(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  removeCollection(key) {
    localStorage.removeItem(key);
  },
  getCollection(key) {
    const stored = localStorage.getItem(key);
    return stored == null ? undefined : JSON.parse(stored);
  },
  getMedia(key, media) {
    const store = this.getCollection(key);

    if (store == null) return undefined;

    const mediaStored = Object.keys(store).find(
      (storeMedia) => storeMedia === media
    );
    return mediaStored;
  },
  setMedia(key, media, data) {
    const store = this.getCollection(key) || {};

    store[media] = data;
    this.add(key, store);
  },
  removeMedia(key, media) {
    const store = this.getCollection(key);

    delete store[media];
    this.add(key, store);
  },
  setId(key, value) {
    this.setMedia(key, "ID", value);
  },
  setCompanion(key, value) {
    this.setMedia(key, "Companion", value);
  },
  setSelfie(key, value) {
    this.setMedia(key, "Selfie", value);
  },
  setImage(key, value) {
    this.setMedia(key, "Image", value);
  },
  setCompanionImage(key, value) {
    this.setMedia(key, "ImageCompanion", value);
  },
  clear() {
    localStorage.clear();
  },
};

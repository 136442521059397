import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import footerImage from "../asset/BeanzFooter.png";
import closeIcon from "../asset/close.svg";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { termsAndConditions } from "../data/termsAndConditions";

const Footer3 = ({ isOpen, toggleFooter, children }) => {
  const [activeSection, setActiveSection] = useState("");
  const [treeLoaded, setTreeLoaded] = useState(false);

  const sectionRefs = useRef({
    "product-disclaimer-section": useRef(null),
    "ip-and-licensing-section": useRef(null),
    "shipping-and-return-policy-section": useRef(null),
    "data-collection-section": useRef(null),
  });

  useEffect(() => {
    /*global TreeNationOffsetWebsite*/
    const treeElem = document.getElementById("tree-nation-offset-website");

    if (treeElem && treeElem.childElementCount <= 0) {
      TreeNationOffsetWebsite({
        code: "65d708ee9c969",
        lang: "en",
        theme: "light",
      }).render("#tree-nation-offset-website");
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (isOpen) {
      setActiveSection("product-disclaimer-section");
      document.getElementById("product-disclaimer-section").scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    const footerHeight = "80%";
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: "0px 0px -50% 0px", threshold: 0.5 }
    );

    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(sectionRefs.current).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [isOpen]);

  const scrollTo = (id) => {
    setActiveSection(id); // Imposta la sezione attiva
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Funzione per aggiungere la classe 'underline' se l'id corrisponde alla sezione attiva
  const getHeaderClass = (id) => {
    return `font-primary cursor-pointer ${
      activeSection === id ? "border-b border-white" : ""
    }`;
  };

  const footerVariants = {
    open: {
      height: "75vh",
      transition: { duration: 0.5 },
    },
    closed: {
      height: "0vh",
      transition: { duration: 0.5 },
    },
  };

  return (
    <div
      className={`fixed bottom-0 bg-[#C87448] rounded-t-[2.4rem] text-white w-full flex flex-col font-light text-[16px] z-[12]`}
    >
      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={footerVariants}
        exit="closed"
        className={`relative bottom-0 bg-[#C87448] rounded-t-[2.4rem] text-white w-full flex flex-col font-light text-[16px]`}
      >
        <img
          src={footerImage}
          alt="Beanz Footer"
          className={`hidden min-[1300px]:block absolute w-[7.8rem] h-[8.4rem] cursor-pointer -top-[8.2rem] transition-all duration-500 ease-in-out z-10 right-10`}
          onClick={toggleFooter}
        />
        {/*PRIMA RIGA */}
        <img
          src={closeIcon}
          onClick={toggleFooter}
          alt="Close Icon"
          className="absolute top-[1rem] left-[2rem] right-0 w-[3.2rem] h-[3.2rem] cursor-pointer mt-4 z-10 transition-none"
        />
        <div className="flex flex-col px-[2.5rem] overflow-hidden">
          <div className="font-primary font-light flex flex-row justify-between px-[16rem] mt-[2rem] text-[2rem]">
            <button
              className={getHeaderClass("product-disclaimer-section")}
              onClick={() => scrollTo("product-disclaimer-section")}
            >
              PRODUCT DISCLAIMER
            </button>
            <button
              className={getHeaderClass("ip-and-licensing-section")}
              onClick={() => scrollTo("ip-and-licensing-section")}
            >
              IP AND LICENSING
            </button>
            <button
              className={getHeaderClass("shipping-and-return-policy-section")}
              onClick={() => scrollTo("shipping-and-return-policy-section")}
            >
              SHIPPING AND RETURN POLICY
            </button>
            <button
              className={getHeaderClass("data-collection-section")}
              onClick={() => scrollTo("data-collection-section")}
            >
              DATA COLLECTION
            </button>
          </div>
          {/*SECONDA RIGA CONTENENTE ELEMENTI A COLONNA */}
          <div
            className="flex flex-col space-y-20 overflow-y-scroll w-full px-[16rem] mt-[60px] pb-[50rem] text-[2rem]"
            style={{ maxHeight: "calc(108% - 18rem)" }}
          >
            {termsAndConditions &&
              termsAndConditions.map((termObject, index) => {
                return (
                  <div key={index}>
                    <h2
                      ref={sectionRefs.current[termObject.id]}
                      className="font-primary mb-2"
                      id={termObject.id}
                    >
                      {termObject.title}
                    </h2>
                    {termObject.description &&
                      termObject.description.map((desc, index) => {
                        if (desc.includes("//{")) {
                          const jsonStart = desc.indexOf("//{");
                          const jsonEnd = desc
                            .substring(jsonStart + 2)
                            .indexOf("}//");
                          const json = desc.substring(
                            jsonStart + 2,
                            jsonStart + jsonEnd + 3
                          );
                          const preJson = desc.substring(0, jsonStart);
                          const postJson = desc.substring(
                            jsonStart + jsonEnd + 5
                          );
                          const textLink = JSON.parse(json);
                          return (
                            <p key={index} className="py-2 font-thin">
                              {preJson}
                              <a
                                href={textLink.link}
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                              >
                                {textLink.title}
                              </a>
                              {postJson}
                            </p>
                          );
                        } else {
                          return (
                            <p key={index} className="py-2 font-thin">
                              {desc}
                            </p>
                          );
                        }
                      })}
                  </div>
                );
              })}
          </div>
        </div>

        {/*TERZA RIGA CONTENENTE ELEMENTI A COLONNA */}
        <div
          className={`bg-[#B2663F] bottom-0 w-screen flex flex-row justify-between px-[18.5rem] ${
            !isOpen ? "h-0 hidden" : "fixed block h-[60px]"
          } items-center mt-20`}
        >
          <span className="font-light text-[1.2rem]">
            {/* Copyright ©2024 Kōbō. All other logos are registered trademarks of
            their respective owner. */}
            KŌBŌ™ All rights reserved.
          </span>
          <div className="flex flex-row items-center justify-between h-full text-[2rem]">
            <div
              id="tree-nation-offset-website"
              className="scale-50 pt-3 min-w-[171px]"
            ></div>
            <a
              href="https://twitter.com/messages/compose?recipient_id=1711767105664581632"
              target="_blank"
              rel="noreferrer"
              className="font-thin pr-10 pl-3"
            >
              SUPPORT
            </a>
            <a
              href="https://twitter.com/kobo_craft"
              target="blank"
              className="font-thin"
            >
              TWITTER
            </a>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Footer3;

import skateClassic from "../asset/skate/shop/Logo_deck_classic.png";
import skateClassic2 from "../asset/skate/shop/Skate Classic 2.png";
import skateRed from "../asset/skate/shop/Logo_deck_red.png";
import skateRed2 from "../asset/skate/shop/Skate RED 2.png";

export const products = {
  prod_QQ2o0VGsTNCB2r: {
    name: "Logo deck Classic",
    price: 80,
    images: [skateClassic, skateClassic2],
    description: `Assorted Color Top Veneers - Chosen at Random.
7-PLY CANADIAN MAPLE

Dimensions and Spec:
SHAPE: POPSICLE
CONCAVE: MELLOW
SIZE: 31.75" X 8"
WHEELBASE: 14.25"
NOSE: 6.75"
TAIL: 6.55"


*Grip not included

Handmade at KŌBŌ Workshop.`,
  },
  prod_QQ3JFIxs68kTLX: {
    name: "Logo deck Red",
    price: 49.99,
    images: [skateRed, skateRed2],
  },
};
